$accent: #23B0FF;
@import "variables";

@import "font";
@import "buttons";
@import "form";

@import "header";
@import "menu";
@import "logo";
@import "main";
@import "post";
@import "pagination";
@import "footer";

@import "prism";
@import "syntax";
@import "code";
@import "terms";
@import "gist";
@import "click_to_zoom_img";
