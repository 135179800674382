@media screen and (min-width: 769px) {
    .post input[type="checkbox"]:checked ~ label > img {
        transform: scale(2.0);
        cursor: zoom-out;
        position: relative;
        z-index: 999;
    }

    .post img.zoomCheck {
        transition: transform 0.15s ease;
        z-index: 999;
        cursor: zoom-in;
    }
}
